<template>
  <main id="Search-page-container">
    <div v-if="templateMode < 3" style="height: 730px"></div>
    <div :class="templateMode < 3 ? 'popupContainer' : ''">
      <div :class="templateMode < 3 ? 'popupStyle' : ''">
        <div class="title">
          <p v-if="templateMode < 3" class="popupTitle">Rechercher</p>
          <!-- <img src="@/assets/icons/etiquette.svg" alt="LogoShowRoomBaby" class="LogoShowRoomBaby"/> -->
        </div>
        <!--<h2 class="ml-3 mt-3">Catégories</h2>
        <div>
            <CarouselComponent />
        </div>
        -->
        <br />

        <form class="ml-auto mr-auto mt-5" @submit.prevent="onFormSubmit">
          <InputValidatorsComponent
            left_icon_input="true"
            class="home-search"
            type="text"
            autocomplete="on"
            placeholder="Que recherchez-vous ?"
            name="keyword"
            :rule="frenchRegex"
            @validationState="setValidatedState"
          />

          <b-form-select
            @change="loadSubcategories(form.category)"
            class="mt-3 mb-3 ml-auto mr-auto"
            v-model="form.category"
            :options="optionsCategorie"
          ></b-form-select>
          <b-form-select
            v-if="form.category != null"
            class="mt-3 mb-3 ml-auto mr-auto"
            v-model="form.subCategory"
            :options="optionsSouscategories"
          ></b-form-select>
          <b-form-select
            @change="sortDpt(form.location_region)"
            class="mt-3 mb-3 ml-auto mr-auto"
            v-model="form.location_region"
            :options="location_region_options"
          ></b-form-select>
          <b-form-select
            v-if="form.location_region != null"
            class="mt-3 mb-3 ml-auto mr-auto"
            v-model="form.location_dpt"
            :options="location_dpt_options"
          ></b-form-select>

          <div class="slidder-container ml-auto mr-auto mt-3 mb-5">
            <div class="topHeader">
              <div class="priceText">Prix</div>
            </div>

            <SliderComponent class="slider" ref="priceSlider" />
          </div>

          <div
            class="button-container ml-auto mr-auto"
            :style="getButtonPartStyle()"
          >
            <div class="horizontalLineSeparator"></div>
            <table>
              <tbody>
                <tr>
                  <td width="100%">
                    <b-button
                      type="submit"
                     
                      class="mr-auto ml-auto"
                      >Rechercher</b-button
                    >
                  </td>
                  <td>
                    <div
                      @click="resetPrice()"
                      @mouseover="effacerOver = true"
                      @mouseleave="effacerOver = false"
                      class="Effacer"
                    >
                      <p>Effacer</p>
                      <div
                        :class="
                          effacerOver ? 'souligne-active' : 'souligne-inactive'
                        "
                      ></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
//import CarouselComponent from './components/carousel-component/carousel-component.vue'
import SliderComponent from "./components/slider-component/slider-component.vue";
import InputValidatorsComponent from "../../shared-component/input-validators-component/input-validators-component.vue";

import { RegionDptSortMixin } from "../../../mixins/region-dpt-sort-mixin.js";
import { CategoryMixin } from "../../../mixins/category-mixin.js";
import { FormValidatorsMixin } from "../../../mixins/form-validators-mixin.js";
import { RegexMixin } from "../../../mixins/regex-mixin.js";
import { SearchOptions } from "../../../services/AnnoncesService.js";

import router from "../../../router";

export default {
  name: "SearchAdPage",
  components: {
    // CarouselComponent,
    SliderComponent,
    InputValidatorsComponent,
  },
  mixins: [RegionDptSortMixin, CategoryMixin, FormValidatorsMixin, RegexMixin],
  props: ["templateMode"],
  data() {
    return {
      // Form values
      form: {
        keyword: "",
        category: null,
        subCategory: null,
        location_region: null,
        location_dpt: null,
      },
      // Form input field, valid or not
      formValid: {
        keyword: false,
      },
      effacerOver: false,
    };
  },
  created() {
    this.initCategoryCSVTab(true);
    this.initRegionCSVTab();
  },
  activated() {
    this.$emit("headerState", 5);
  },
  methods: {
    onFormSubmit(event) {
      console.log("onFormSubmit : " + event);

      let searchOptions = new SearchOptions();
      searchOptions.search = this.form.keyword;
      searchOptions.cat = this.form.category;
      searchOptions.scat = this.form.subCategory;
      searchOptions.region = this.form.location_region;
      searchOptions.dept = this.form.location_dpt;
      searchOptions.prixmin = this.$refs.priceSlider.value[0];
      searchOptions.prixmax = this.$refs.priceSlider.value[1];

      router.push({ path: "/categorie/" + searchOptions.getRequest() });
    },
    resetPrice() {
      console.info("resetPrice()");
      //this.$refs.priceSlider.resetValues();
      location.reload();
    },

    getButtonPartStyle() {
      if (this.templateMode < 3) {
        return "max-width:500px; margin: 0 auto;position:relative;";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss">
@import "search-page.scss";
</style>