<template>
    <div class="slider-component">
        <vue-slider 
            v-model="value" 
            :enable-cross="false"
            :min="0"
            :max="1500"
            :tooltip='tooltip'
            :tooltip-formatter="val => val + '€'">
        </vue-slider>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
    name: "SliderComponent",
    components: {
        VueSlider
    },
    data: function () {
        return {
            value: [0, 1500],
            tooltip: 'always'
        }
    },
    methods: {
       resetValues(){
           this.value = [0, 1500];
       }
    }
};
</script>

<style lang="scss">
    @import "slider-component.scss";
</style>